import React from 'react';
import PropTypes from 'prop-types';

import { MapMarkerAltSolidIcon } from '../../../shared/icons/icons';

import './my-store-map-marker.scss';

function MyStoreMapMarker(props) {
  const { onClick } = props;

  return <MapMarkerAltSolidIcon onClick={onClick} styleName="icon" />;
}

MyStoreMapMarker.propTypes = {
  onClick: PropTypes.func
};

export default React.memo(MyStoreMapMarker);
