import React, {
  KeyboardEventHandler,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  InfoCircleRegularIcon,
  InfoCircleSolidIcon
} from '../../shared/icons/icons';
import Button from '../button/button';
import Popover from '../popover/popover';
import Typography from '../typography/typography';

import {
  bindEvent,
  unbindEvent
} from '../../shared/utils/event-utils/event-utils';
import generateUID from '../../shared/utils/generate-uid/generate-uid';

import './tooltip.scss';

interface ButtonProps {
  onClick: ((event: SyntheticEvent<Element, Event>) => void) | undefined;
  onKeyDown: KeyboardEventHandler<HTMLButtonElement> | undefined;
  onKeyPress: KeyboardEventHandler<HTMLButtonElement> | undefined;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface TooltipProps {
  ariaLabel?: string;
  className?: string;
  content?: ReactNode;
  contentStyle?: object;
  hoverOver?: boolean;
  onClick?: () => void;
  popoverClassName?: string;
  render?: ReactNode;
  rootElementId?: string;
}

function Tooltip(props: TooltipProps) {
  const {
    'ariaLabel': ariaLabel,
    className = '',
    content,
    hoverOver,
    onClick,
    popoverClassName,
    render,
    rootElementId = 'tooltip-root'
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const uid = useMemo(generateUID, []);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isTouchEnabled =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const isHoverOverEnabled = hoverOver && !isTouchEnabled;
  const isOnClickEnabled = !hoverOver || isTouchEnabled;

  function showTooltip() {
    let event;

    if (typeof Event === 'function') {
      event = new Event('tooltip');
    } else {
      event = document.createEvent('Event');
      event.initEvent('tooltip', false, false);
    }

    window.dispatchEvent(event);
    setIsOpen(true);
  }

  function hideTooltip() {
    setIsOpen(false);
  }

  function toggleTooltip(e) {
    e.preventDefault();
    e.stopPropagation();

    isOnClickEnabled && onClick?.();
    if (!isOpen) {
      showTooltip();
    } else {
      hideTooltip();
    }
  }

  function handleEscapeKey(e) {
    if (e?.key === 'Escape') {
      hideTooltip();
    }
  }

  const buttonProps: ButtonProps = {
    onClick: isOnClickEnabled ? toggleTooltip : undefined,
    onKeyDown: handleEscapeKey,
    onKeyPress: toggleTooltip
  };

  if (isHoverOverEnabled) {
    buttonProps.onMouseEnter = showTooltip;
    buttonProps.onMouseLeave = hideTooltip;
  }

  const Icon = !render && isOpen ? InfoCircleSolidIcon : InfoCircleRegularIcon;

  useEffect(() => {
    function handleClickOutside(e) {
      if (!buttonRef.current?.contains(e.target)) {
        hideTooltip();
      }
    }
    bindEvent(window, ['scroll', 'resize', 'tooltip'], hideTooltip, true);
    bindEvent(window, ['click'], handleClickOutside);
    return () => {
      unbindEvent(window, ['scroll', 'resize', 'tooltip'], hideTooltip, true);
      unbindEvent(window, ['click'], handleClickOutside);
    };
  }, [buttonRef]);

  //* Note: Our tooltip uses React Portal and dynamically renders so it is not reachable by voiceover technology. In ARIA 1.3 aria-describedby can be replaced with aria-description for better looking code. For now we have the hidden typography so that we can describe the icon.
  return (
    <>
      <Button
        aria-describedby={uid}
        aria-label={ariaLabel || 'tooltip'}
        className={`tooltip ${className}`}
        ref={buttonRef}
        styleName="button"
        {...buttonProps}
      >
        {render ? render : <Icon styleName="icon" />}
      </Button>
      <Popover
        className={popoverClassName || 'tooltip__popover'}
        isOpen={isOpen}
        maxWidth={335}
        role="tooltip"
        rootElementId={rootElementId}
        styleName="popover"
        triggerRef={buttonRef}
        verticalOffset={3}
      >
        <Typography
          aria-live="polite"
          className="tooltip__content"
          styleName="content"
        >
          {content}
        </Typography>
      </Popover>
      <Typography className="display-none" id={uid} tag="span">
        {content}
      </Typography>
    </>
  );
}

export default Tooltip;
