import React, { forwardRef, useMemo } from 'react';

import Input from '../input/input';
import { InputError } from '../input/input-types';
import Typography from '../typography/typography';

import generateUID from '../../shared/utils/generate-uid/generate-uid';

import './text.scss';

interface TextProps {
  autoCapitalize?: string;
  autoComplete?: string;
  autoCorrect?: string;
  className?: string;
  error?: InputError;
  id?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  noValidate?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  pattern?: string;
  shouldAutoFocus?: boolean;
  tabIndex?: number;
  type?: string;
  value?: string;
}

const Text = forwardRef<HTMLInputElement, React.PropsWithChildren<TextProps>>(
  function Text(props, ref) {
    const {
      autoCapitalize,
      autoComplete,
      autoCorrect = 'off',
      children,
      id,
      inputMode,
      isDisabled = false,
      isReadOnly = false,
      isRequired = false,
      name,
      noValidate = false,
      onBlur,
      onChange,
      onFocus,
      onKeyPress,
      pattern,
      shouldAutoFocus,
      tabIndex = '0',
      type = 'text',
      value
    } = props;
    const inputId = useMemo(() => id || generateUID(), [id]);

    return (
      <Input {...props} id={inputId}>
        <Typography
          aria-required={isRequired}
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoFocus={shouldAutoFocus}
          data-testid={`${name}-input`}
          disabled={isDisabled}
          formNoValidate={noValidate}
          id={inputId}
          inputMode={inputMode}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          pattern={pattern}
          readOnly={isReadOnly}
          ref={ref}
          required={isRequired}
          styleName="text-input"
          tabIndex={tabIndex}
          tag="input"
          type={type}
          value={value}
          variant="P"
        />
        {children}
      </Input>
    );
  }
);

export default Text;
