import { useCallback, useRef } from 'react';
import { DocumentNode } from 'graphql';

import { useQuery } from '../hooks';

interface UseLazyQueryOptions {
  fetchPolicy?: 'no-cache' | 'network-only' | string;
  variables?: object;
}

function useLazyQuery(
  rawQuery: string | DocumentNode,
  options?: UseLazyQueryOptions
) {
  const allOptions = options || {};
  const { fetchPolicy } = allOptions;
  const baseQueryVariablesRef = useRef(options?.variables || {});
  const prevQueryVariablesRef = useRef({});

  baseQueryVariablesRef.current = options?.variables || {};

  const { data, error, hasBeenCalled, isLoading, refetch, status } = useQuery(
    rawQuery,
    {
      ...allOptions,
      skip: true
    }
  );

  const fetch = useCallback(
    variables => {
      const fetchVars = {
        ...baseQueryVariablesRef.current,
        ...(variables || {})
      };
      const hasQueryVariablesChanged =
        JSON.stringify(fetchVars) !==
        JSON.stringify(prevQueryVariablesRef.current);
      const shouldFetch =
        fetchPolicy === 'no-cache' ||
        fetchPolicy === 'network-only' ||
        hasQueryVariablesChanged;

      if (shouldFetch) {
        prevQueryVariablesRef.current = fetchVars;

        return refetch(fetchVars);
      }
    },
    [refetch, fetchPolicy]
  );

  return {
    data,
    error,
    fetch,
    hasBeenCalled,
    isLoading,
    status
  };
}

export default useLazyQuery;
