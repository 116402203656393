import React from 'react';
import PropTypes from 'prop-types';

import './store-counts-by-location.scss';

function StoreCountsByLocation(props) {
  const { locations, onClick } = props;

  return (
    <ul styleName="container">
      {Array.isArray(locations) &&
        locations.map((location, idx) => (
          <li key={idx} styleName="list-item">
            <button
              name={location.name}
              onClick={() => onClick(location.name)}
              styleName="location"
            >
              {location.name.toLowerCase()} ({location.storeCount})
            </button>
          </li>
        ))}
    </ul>
  );
}

StoreCountsByLocation.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      storeCount: PropTypes.number.isRequired
    })
  ),
  onClick: PropTypes.func.isRequired
};

export default StoreCountsByLocation;
