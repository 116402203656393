import React, { useCallback, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import MapMarker from './components/map-marker';
import MyLocationMarker from './components/my-location-marker';
import MyStoreMapMarker from './components/my-store-map-marker';

import './dynamic-map.scss';

const MIN_ZOOM = 5;
const MAX_ZOOM = 13;

function DynamicMap(props) {
  const {
    apiKey,
    centerPos,
    markers,
    myStorePos,
    onMyStoreClick,
    onNearbyStoreClick,
    userPos,
    zoom = 10
  } = props;

  const googleMapsRef = useRef();
  const hasMarkers = Array.isArray(markers) && markers.length > 0;

  function getMapBounds(maps, markers) {
    if (maps && markers) {
      const bounds = new maps.LatLngBounds();
      markers.forEach(marker => {
        bounds.extend(new maps.LatLng(marker?.latitude, marker?.longitude));
      });
      return bounds;
    }
  }

  const adjustMap = useCallback(() => {
    const { map, maps } = googleMapsRef.current || {};

    if (map && maps) {
      if (hasMarkers) {
        const bounds = getMapBounds(maps, markers);
        if (bounds) {
          map.fitBounds(bounds);
        }
      }
      map.setOptions({ maxZoom: MAX_ZOOM, minZoom: MIN_ZOOM });
    }

    if (map && centerPos) {
      map.panTo({
        lat: centerPos?.latitude,
        lng: centerPos?.longitude
      });
    }
  }, [centerPos, hasMarkers, markers]);

  useEffect(() => {
    adjustMap();
  }, [adjustMap]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: apiKey || null
      }}
      center={{
        lat: centerPos?.latitude,
        lng: centerPos?.longitude
      }}
      maxZoom={MAX_ZOOM}
      onGoogleApiLoaded={googleMaps => {
        googleMapsRef.current = googleMaps;
        adjustMap();
      }}
      yesIWantToUseGoogleMapApiInternals
      zoom={zoom}
    >
      {userPos && (
        <MyLocationMarker lat={userPos.latitude} lng={userPos.longitude} />
      )}

      {myStorePos && (
        <MyStoreMapMarker
          lat={myStorePos.latitude}
          lng={myStorePos.longitude}
          onClick={onMyStoreClick}
        />
      )}
      {hasMarkers &&
        markers.map((marker, idx) => (
          <MapMarker
            isActive={marker?.isActive}
            key={idx}
            lat={marker?.latitude}
            lng={marker?.longitude}
            number={marker?.markerNumber}
            onClick={() => onNearbyStoreClick(idx + 1)}
            onKeyPress={() => onNearbyStoreClick(idx + 1)}
            styleName={marker?.pitPass ? 'pitpass-map-marker' : ''}
          />
        ))}
    </GoogleMapReact>
  );
}

DynamicMap.propTypes = {
  apiKey: PropTypes.string,
  centerPos: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.boolean,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      markerNumber: PropTypes.number
    })
  ),
  myStorePos: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  onMyStoreClick: PropTypes.func,
  onNearbyStoreClick: PropTypes.func,
  userPos: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  zoom: PropTypes.number
};

export default DynamicMap;
